interface CheckboxProps {
  label: string;
  name: string;
  checked: boolean;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  className?: string;
}

export default function Checkbox(props: CheckboxProps) {
  const { label, checked, onChange, className, name } = props;
  return (
    <label className={`flex items-center mt-2 ${className}`}>
      <input
        type="checkbox"
        name={name}
        checked={checked}
        onChange={onChange}
        className="h-5 w-5 min-h-5 min-w-5"
      />
      <span className="ml-2 poppins font-light text-text-primary">{label}</span>
    </label>
  );
}
