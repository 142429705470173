import Countdown from './components/count-down';

import Form from './components/form';
import BrandsTable from './components/brands-table';

import Footer from './layout/footer';
import HeroVideo from './components/hero-video';
import HeroText from './components/hero-text';

export default function App() {
  return (
    <div>
      {/* hero section */}
      <div className="flex flex-col items-center py-20 relative">
        <img src="images/logo.png" alt="logo" className="h-20 w-72" />
        <div className="w-full flex flex-col px-10 md:px-5 md:grid md:grid-cols-12 gap-10 maxw center mt-20">
          {/* hero left side */}
          <div className="col-span-7 flex flex-col">
            <HeroText />
            <Countdown />
          </div>
          {/* hero right side */}
          <div className="col-span-5 w-auto h-full">
            <Form />
          </div>
        </div>
        {/* hero background */}
        <div className="w-full h-full object-cover absolute inset-0 -z-10">
          <HeroVideo />
        </div>
      </div>

      {/* brands */}
      <div className="bg-background-light">
        <div className="flex flex-col items-center justify-center maxw center px-10 pt-7 pb-10">
          <div className="text-text-primary poppins mb-10 font-semibold">
            Together in the Journey
          </div>
          <div className="hidden lg:flex flex-row flex-wrap justify-center items-center">
            <BrandsTable colCount={5} />
          </div>
          <div className="hidden md:flex lg:hidden flex-row flex-wrap justify-center items-center">
            <BrandsTable colCount={3} />
          </div>
          <div className="flex md:hidden flex-row flex-wrap justify-center items-center">
            <BrandsTable colCount={2} />
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
}
