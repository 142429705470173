export default function HeroVideo() {
  return (
    <video
      autoPlay
      loop
      muted
      playsInline
      disablePictureInPicture
      className="w-full h-full object-cover"
    >
      <source src="videos/herovideo.mp4" type="video/mp4" />
      Your browser does not support the video tag.
    </video>
  );
}
