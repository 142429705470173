import React, { useState, useEffect } from 'react';
import { CountdownTargetDate as targetDate } from '../const/staticVariables';

const Countdown = () => {
  const [timeLeft, setTimeLeft] = useState({
    days: 0,
    hours: 0,
    minutes: 0,
    seconds: 0,
  });

  useEffect(() => {
    const intervalId = setInterval(() => {
      const now = new Date();
      const difference = targetDate.getTime() - now.getTime();

      // Zaman kalmadıysa, sayımı durdur
      if (difference <= 0) {
        clearInterval(intervalId);
        return;
      }

      // Gün, saat, dakika ve saniyeleri hesapla
      const days = Math.floor(difference / (1000 * 60 * 60 * 24));
      const hours = Math.floor((difference / (1000 * 60 * 60)) % 24);
      const minutes = Math.floor((difference / 1000 / 60) % 60);
      const seconds = Math.floor((difference / 1000) % 60);

      setTimeLeft({ days, hours, minutes, seconds });
    }, 1000);

    // Bileşen demonte olursa interval'i temizle
    return () => clearInterval(intervalId);
  }, [targetDate]);

  // Render edilecek zaman dilimleri
  const timeUnits = [
    { label: 'Days', value: timeLeft.days },
    { label: 'Hours', value: timeLeft.hours },
    { label: 'Minutes', value: timeLeft.minutes },
    { label: 'Seconds', value: timeLeft.seconds },
  ];

  return (
    <div className="flex gap-5 mt-10">
      {timeUnits.map((unit, index) => (
        <div
          key={index}
          className="flex flex-col items-center justify-center bg-background-blue h-24 w-24"
        >
          <div className="text-white text-4xl hind">{unit.value}</div>
          <div className="text-white text-lg font-medium">{unit.label}</div>
        </div>
      ))}
    </div>
  );
};

export default Countdown;
