export const heroMainText = ['GREAT IDEAS TAKE TIME...', 'ALMOST ', 'THERE.'];
export const heroSubText =
  'Peripol thrives on a culture of shared understanding, where curiosity and enthusiasm fuel innovation. Every connection is built on trust and loyalty, creating a bond that makes Peripol more than just a creative space—it’s where passion becomes purpose.';

export const CountdownTargetDate = new Date('2024-12-01T00:00:00');

export const inputs = [
  { name: 'name', type: 'text', placeholder: 'Name', required: true },
  {
    name: 'email',
    type: 'email',
    placeholder: 'Email Address',
    required: true,
  },
  {
    name: 'description',
    type: 'text',
    placeholder: 'Description',
    required: false,
  },
  {
    name: 'company',
    type: 'text',
    placeholder: 'Company Name',
    required: true,
  },
  {
    name: 'position',
    type: 'text',
    placeholder: 'Position',
    required: false,
  },
  {
    name: 'industry',
    type: 'text',
    placeholder: 'Industry',
    required: false,
  },
];

export const checkboxes = [
  { label: 'Brand Strategy & Dev.', name: 'brandStrategy' },
  { label: 'Digital Marketing Solutions', name: 'digitalMarketing' },
  {
    label: 'Internal & Corporate Communication Solutions',
    name: 'communicationSolutions',
  },
  { label: 'Social Media Campaigns', name: 'socialMedia' },
  { label: 'Video Prod. & Motion Graphics', name: 'videoProduction' },
  { label: 'Software & Digital Product Dev.', name: 'softwareDevelopment' },
  { label: 'General Inquiry/Intro.', name: 'generalInquiry' },
];

export const companies = [
  'Airties',
  'Bosch',
  'BSH',
  'Burn',
  'Cappy',
  'Coca Cola İçecek',
  'CCI',
  'Damla',
  'Eczacıbaşı',
  'Eczacıbaşı Monrol',
  'Fusetea',
  'Gaggenau',
  'Gaviscon',
  'KOÇ Holding',
  'Koç Sistem',
  'Kotex',
  'Milangaz',
  'Monster',
  'Nestle',
  'Nivea',
  'Oyak',
  'Philips',
  'Profilo Ev Aletleri',
  'Reckitt Benckiser',
  'Renault',
  'Siemens',
  'Socar',
  'Türk Hava Yolları',
  'Tüpraş',
  'Unilever',
  'Togg',
  'Türk Hava Yolları Havacılık Akademisi',
  'Tüpraş',
  'TCMB',
  'Yıldız Holding',
];

export const footerSubsidaries = [
  { name: 'Alesta', url: 'https://alesta3p.com/' },
  { name: 'Ozware', url: 'https://ozware.dev/' },
  { name: 'Pasmanica', url: '' },
];

export const footerText =
  ' All content, ideas, and information on this website are the property of Peripol Creative and are provided for informational purposes only. Any copying, sharing, or commercial use of any part of this content without written permission is strictly prohibited. The information on this site does not constitute any legal obligation and is intended for general informational purposes. Unauthorized use will result in legal action.';

export const subfooterText =
  '© 2024 Peripol Craetive. All Rights Reserved. From the hands of PeripolTeam.';
