import { companies } from '../const/staticVariables';

export default function BrandsTable({ colCount }: { colCount: number }) {
  const rows = [];
  for (let idx = 0; idx < companies.length; idx++) {
    const isStartOfRow = idx % colCount === 0;
    if (isStartOfRow) {
      rows.push(
        <div key={idx} className="flex flex-wrap w-full">
          {companies.slice(idx, idx + colCount).map((company, companyIdx) => (
            <div
              key={`${idx}-${companyIdx}`}
              className={`flex-1 text-center py-8 px-8 text-text-primary font-normal 
                            border-gray-400 ${companyIdx !== 0 && 'border-l'} ${
                idx !== 0 && 'border-t'
              }`}
            >
              {company}
            </div>
          ))}
        </div>
      );
    }
  }

  return <div>{rows}</div>;
}
