import {
  footerSubsidaries,
  footerText,
  subfooterText,
} from '../const/staticVariables';

export default function Footer() {
  return (
    <>
      <div className="grid grid-rows-1 md:grid-cols-3 bg-background-dark gap-20 md:gap-0 p-20">
        <div className="flex flex-col items-start justify-center text-white font-light gap-5">
          <img
            src="images/footer-logo.png"
            alt="footer-logo"
            className="h-16 w-52"
          />
          <a href="mailto:official@peripol.com">official@peripol.com</a>
          <a href='tel:"+90 212 211 62 32"'>+90 212 211 62 32</a>
          <div>Esentepe Mah. Kasap Sk. No:19-20 Şişli İstanbul</div>
          <div className="flex gap-5">
            <a
              target="_blank"
              href="https://www.linkedin.com/company/peripolcreative/mycompany/verification/"
            >
              <img
                src="icons/linkedin.svg"
                alt="linkedin"
                className="w-10 h-10 mt-4"
              />
            </a>
            <a target="_blank" href="https://maps.app.goo.gl/to2iLreubJ9Fiygp6">
              <img src="icons/map.svg" alt="map" className="w-10 h-10 mt-4" />
            </a>
          </div>
        </div>
        <div className="flex flex-col items-center justify-center text-white font-light gap-5">
          <div className="font-medium">Subsidaries</div>
          {footerSubsidaries.map((link, index) =>
            link.url ? (
              <a
                key={index}
                href={link.url}
                target="_blank"
                rel="noopener noreferrer"
              >
                {link.name}
              </a>
            ) : (
              <span key={index}>{link.name}</span>
            )
          )}
        </div>
        <div className="flex hind flex-col items-center justify-center text-white font-light gap-5">
          <div>{footerText}</div>
        </div>
      </div>
      <div className="flex py-5 px-5 justify-center items-center bg-background-black text-white font-light">
        {subfooterText}
      </div>
    </>
  );
}
