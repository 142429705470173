import { heroMainText, heroSubText } from '../const/staticVariables';

export default function HeroText() {
  return (
    <div className="text-white bebas text-7xl">
      {heroMainText[0]}
      <div>
        {heroMainText[1]}
        <span className="text-text-blue">{heroMainText[2]}</span>
      </div>
      {/* hero sub text */}
      <div className="poppins text-xl font-normal mt-5">{heroSubText}</div>
    </div>
  );
}
